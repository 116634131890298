import React from "react";
import { Carousel } from "react-bootstrap";
import Img1 from "../assets/images/post-img3.jpeg";
import Img2 from "../assets/images/post-img4.png";
import Img3 from "../assets/images/post-img4.jpeg";
import Img4 from "../assets/images/post-image5.png";
import styled from "styled-components";
import { BoxContainer, Container, Heading, HeroSection } from "./styles";

const HeroText = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: 768px) {
    text-align: center;
    flex-direction: column;
  }
`;

const HeroCarousel = styled.div`
  width: 50%;
  min-width: 450px;
  margin-left: 30px;

  @media (max-width: 768px) {
    width: 100%;
    min-width: auto;
    margin-left: 0;
  }
`;

const AboutSection = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 50px;

  @media (max-width: 768px) {
    margin-bottom: 30px;
  }
`;

const AboutContent = styled.div`
  display: flex;

  align-items: center;

  @media (max-width: 768px) {
    text-align: center;
    flex-direction: column;
  }
`;

const AboutCarousel = styled.div`
  width: 50%;
  min-width: 450px;
  margin-right: 30px;

  @media (max-width: 768px) {
    width: 100%;
    min-width: auto;
    margin-right: 0;
  }
`;

const HighlightsSection = styled.section`
  padding: 50px 20px;
  background-color: #f8f8f8;
  text-align: center;
`;

const Highlights = styled.div`
  display: flex;
  justify-content: space-around;
  gap: 20px;
  flex-wrap: wrap;

  @media (max-width: 768px) {
    gap: 10px;
    flex-direction: column;
    align-items: center;
  }
`;

const HighlightItem = styled.div`
  background-color: #ffffff;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 20px;
  width: 30%;
  min-width: 250px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition:
    transform 0.3s ease,
    box-shadow 0.3s ease;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 10px rgba(0, 0, 0, 0.15);
  }

  @media (max-width: 768px) {
    width: 80%;
    margin-bottom: 20px;
  }
`;

const IconContainer = styled.div`
  font-size: 40px;
  color: #fd7e14;
  margin-bottom: 15px;
`;

const HighlightTitle = styled.h3`
  font-size: 22px;
  color: #220033;
  margin-bottom: 10px;
`;

const HighlightText = styled.p`
  font-size: 16px;
  color: #555;
`;

const SubHeading = styled.h2`
  font-size: 2rem !important;
  color: #f5903d !important;
  margin-bottom: 25px !important;
  margin-top: 30px !important;

  @media (max-width: 768px) {
    font-size: 1.5rem !important;
    margin-bottom: 15px !important;
  }
`;

const BoldText = styled.b`
  font-size: 14px;
  color: #f5903d;
  font-family: "Jost", sans-serif !important;
`;

const CarouselImage = styled.img`
  width: 100%;
  transition: transform 0.3s ease-in-out;
  border-radius: 30px;
  min-height: 400px !important;
  max-height: 530px !important;

  &:hover {
    transform: scale(1.1);
  }
`;

const QuemSomos = () => {
  return (
    <Container>
      <BoxContainer>
        <HeroSection>
          <Heading>Nossa História</Heading>
          <HeroText>
            <p>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;O Instituto Cecília Meireiles é uma
              Associação Civil de Direito Privado, sem fins econômicos ou
              lucrativos, fundada em 08 de Março de 1986. Nossa missão é
              promover o pleno exercício de cidadania, fortalecendo e
              propiciando a integração social e comunitária.
              <br />
              <br />
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Os membros da associação, em
              conjunto com alguns participantes ativos na ONG e da comunidade
              pertencentes da zona leste de São Paulo, criou se o{" "}
              <BoldText>PROJETO CRESCENDO COM ATITUDE.</BoldText>
              Este Projeto nasceu para vim de encontro diretamente com a
              necessidades básicas que contribuem para o crescimento, inclusão
              social, ou seja, para o despertar educacional e social em todos os
              sentidos.
              <br />
              <br />
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; O Instituto Cecilia Meireiles,
              desenvolve desde 2019 ações socioeducativas com crianças
              adolescentes e suas famílias na comunidade do Parque São Rafael e
              Jardim Elba em São Paulo. Atualmente a organização atende em média
              2.400 crianças de 0 a 3 anos, através dos Centro de Educação
              Infantil, e em média 600 mulheres vítimas de violência doméstica e
              seus filhos, através da Casa Abrigo e da Casa de Passagem.
            </p>

            <HeroCarousel>
              <Carousel
                controls={false}
                indicators
                interval={4000}
                pause={false}
              >
                <Carousel.Item>
                  <CarouselImage src={Img1} alt="Primeira imagem" />
                </Carousel.Item>
                <Carousel.Item>
                  <CarouselImage src={Img2} alt="Segunda imagem" />
                </Carousel.Item>
              </Carousel>
            </HeroCarousel>
          </HeroText>
        </HeroSection>

        <HighlightsSection>
          <SubHeading>Áreas de Atuação</SubHeading>
          <Highlights>
            <HighlightItem>
              <IconContainer>
                <i className="fas fa-book-open"></i>
              </IconContainer>
              <HighlightTitle>Educação</HighlightTitle>
              <HighlightText>
                • Desenvolvemos centros educacionais para crianças de 0 a 3
                anos, promovendo a educação infantil com qualidade.
              </HighlightText>
              <HighlightText>
                • Promovemos cursos, palestras, seminários, vivências,
                encontros, eventos sociais culturais, grupos de estudos e
                treinamentos nas atividades desenvolvidas.
              </HighlightText>
            </HighlightItem>
            <HighlightItem>
              <IconContainer>
                <i className="fas fa-heartbeat"></i>
              </IconContainer>
              <HighlightTitle>Saúde</HighlightTitle>
              <HighlightText>
                • Atendemos famílias e idosos com foco em prevenção e
                encaminhamento a unidades básicas de saúde.
              </HighlightText>
              <HighlightText>
                {" "}
                • Distribuição de alimentos para a comunidade local.
              </HighlightText>
            </HighlightItem>
            <HighlightItem>
              <IconContainer>
                <i className="fas fa-hands-helping"></i>
              </IconContainer>
              <HighlightTitle>Assistência Social</HighlightTitle>
              <HighlightText>
                • Oferecemos apoio a mulheres vítimas de violência e seus filhos
                através de casas abrigo e de passagem.
              </HighlightText>
              <HighlightText>
                • Garantimos o desenvolvimento econômico e o combate à pobreza e
                visando a promoção da inclusão social.
              </HighlightText>
            </HighlightItem>
          </Highlights>
        </HighlightsSection>

        <AboutSection>
          <SubHeading>Nosso Trabalho</SubHeading>
          <AboutContent>
            <AboutCarousel>
              <Carousel
                controls={false}
                indicators
                interval={4000}
                pause={false}
              >
                <Carousel.Item>
                  <CarouselImage src={Img3} alt="Primeira imagem" />
                </Carousel.Item>
                <Carousel.Item>
                  <CarouselImage src={Img4} alt="Segunda imagem" />
                </Carousel.Item>
              </Carousel>
            </AboutCarousel>
            <p>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Atuamos em diversas áreas como
              Assistência Social, Saúde, Educação e Direitos Humanos, com foco
              em mitigar a permanência de crianças e adolescentes em vias
              públicas, distribuir alimentos e promover a inclusão social e
              proteção de pessoas em situação de vulnerabilidade.
              <br />
              <br />
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;A Organização tem como missão
              “promover pleno exercício de cidadania objetivando fortalecer e
              propiciar a integração social e comunitária, desenvolvendo ações
              de inclusão e conquista da dignidade humana”.
              <br /> <br />
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Hoje nós temos experiência
              comprovada em diferentes áreas, por meio da{" "}
              <BoldText>execução de programas, projetos e serviços</BoldText>,
              nas seguintes políticas públicas:
              <br />
              <BoldText>Centro Educacional:</BoldText> <br />
              • Infantil CEI Crescendo com Atitude Unidade 1 <br />
              • Infantil CEI Crescendo com Atitude Unidade 2. <br />
              <BoldText>Assistência Social e Direitos Humanos:</BoldText>
              <br />
              • Programa Casa Abrigo para Mulheres Vítimas de Violência
              <br />
              • Serviço de Convivência e Fortalecimento de Vínculos
              <br />
              • Programa Casa Lar
              <br />
              • Programa Casa de Passagem para mulheres Vítimas de Violência
              <br />
              • Atendimento a famílias e idosos <br />• Distribuição de Cestas
              Básicas.
            </p>
          </AboutContent>
        </AboutSection>
      </BoxContainer>
    </Container>
  );
};

export default QuemSomos;

import React from "react";
import styled from "styled-components";

const MiniMenuContainer = styled.div`
  position: fixed;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
  z-index: 1000;
  opacity: 0.5;
  transition: opacity 0.3s ease;

  &:hover {
    opacity: 1;
  }
`;

const MiniMenuItem = styled.a`
  position: relative;
  width: 40px;
  height: 40px;
  background-color: #220033;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition:
    transform 0.3s ease,
    background-color 0.3s ease,
    color 0.3s ease;
  color: #fd7e14;
  font-size: 20px;
  text-decoration: none;

  &:hover {
    transform: scale(1.2);
    background-color: #fd7e14;
    color: #220033;

    &::after {
      content: attr(data-title);
      position: absolute;
      top: 50%;
      right: 100%;
      transform: translateY(-50%) translateX(-10px);
      background-color: rgba(0, 0, 0, 0.7);
      color: #fff;
      padding: 2px 8px;
      border-radius: 5px;
      white-space: nowrap;
      font-size: 12px;
      opacity: 1;
      transition:
        opacity 0.3s ease,
        transform 0.3s ease;
    }
  }

  &:not(:hover)::after {
    opacity: 0;
  }

  @media (max-width: 768px) {
    width: 30px;
    height: 30px;
    font-size: 16px;

    &:hover::after {
      font-size: 10px;
      right: 80%;
    }
  }
`;

const MiniMenu = ({ projects }) => {
  return (
    <MiniMenuContainer>
      {projects.map((project) => (
        <MiniMenuItem
          key={project.idDiv}
          href={`#${project.idDiv}`}
          aria-label={project.title}
          data-title={project.title}
        >
          {project.icon}
        </MiniMenuItem>
      ))}
    </MiniMenuContainer>
  );
};

export default MiniMenu;

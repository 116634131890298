import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const BoxContainer = styled.div`
  width: 100%;
  max-width: 1110px;
  padding-top: 70px;
`;
export const HeroSection = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 50px 0;

  @media (max-width: 768px) {
    margin: 30px 0;
  }
`;
export const Heading = styled.h1`
  font-size: 2rem !important;
  color: #f5903d !important;
  margin-bottom: 25px !important;

  @media (max-width: 768px) {
    font-size: 1.5rem !important;
    margin-bottom: 15px !important;
  }
`;

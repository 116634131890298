import React from 'react';
import { BoxContainer, Container } from './styles';

function Fundadores() {
  return (
    <Container>
      <BoxContainer>
      <h1>Bem-vindo à nossa ONG</h1>
      <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque et euismod ligula...</p>
      </BoxContainer>
    </Container>
  ); 
}

export default Fundadores;

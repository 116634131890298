// Home.js
import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

import homeImage from "../assets/images/image.png"; // Imagem de fundo da home
import missionImage from "../assets/images/missao.jpg"; // Imagem da seção "Missão"
import projectsImage from "../assets/images/projetos.png"; // Imagem da seção "Projetos"
import donateImage from "../assets/images/doacao.png"; // Imagem para a seção "Doações"
import contactImage from "../assets/images/contato.png"; // Imagem para a seção "Contato"
import { BoxContainer, Container } from "./styles";

const HomeContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f9f9f9;
`;

const HeroSection = styled.section`
  width: 100%;
  height: 500px;
  background: url(${homeImage}) no-repeat center center;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  text-align: center;
`;

const HeroTitle = styled.h1`
  font-size: 3rem;
  font-weight: bold;
`;

const HeroSubtitle = styled.p`
  font-size: 1.5rem;
  margin-top: 10px;
`;

const SectionsContainer = styled.div`
  width: 90%;
  max-width: 1200px;
  margin: 20px auto;
`;

const Section = styled.section`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 30px 0;
  background-color: #fff;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  overflow: hidden;

  &:nth-child(even) {
    flex-direction: row-reverse;
  }
`;

const SectionImage = styled.div`
  width: 50%;
  background-size: cover;
  background-position: center;
`;

const SectionContent = styled.div`
  width: 50%;
  padding: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const SectionTitle = styled.h2`
  color: #220033;
  margin-bottom: 15px;
`;

const SectionDescription = styled.p`
  font-size: 1.1rem;
  color: #666;
`;

const Button = styled(Link)`
  display: inline-block;
  margin-top: 20px;
  padding: 10px 20px;
  border-radius: 5px;
  background-color: #fd7e14;
  color: white;
  font-weight: bold;
  text-decoration: none;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #f57c00;
  }
`;

function Home() {
  return (
    <Container>
      <BoxContainer>
        <HomeContainer>
          {/* Hero Section */}
          <HeroSection>
            <HeroTitle>Instituto Cecília Meireles</HeroTitle>
            <HeroSubtitle>
              Educação e Apoio à Sociedade com Propósito e Dedicação
            </HeroSubtitle>
          </HeroSection>

          {/* Main Sections */}
          <SectionsContainer>
            {/* Mission Section */}
            <Section>
              <SectionImage
                style={{ backgroundImage: `url(${missionImage})` }}
              />
              <SectionContent>
                <SectionTitle>Nossa Missão</SectionTitle>
                <SectionDescription>
                  No Instituto Cecília Meireles, promovemos a educação e o apoio
                  social para comunidades vulneráveis. A nossa missão é oferecer
                  acesso ao conhecimento e assistência para todos, construindo
                  um futuro mais justo.
                </SectionDescription>
                <Button to="/quem-somos">Saiba Mais</Button>
              </SectionContent>
            </Section>

            {/* Projects Section */}
            <Section>
              <SectionImage
                style={{ backgroundImage: `url(${projectsImage})` }}
              />
              <SectionContent>
                <SectionTitle>Projetos</SectionTitle>
                <SectionDescription>
                  Nossos projetos incluem ações de educação, assistência social,
                  e direitos humanos. Cada um é pensado para causar um impacto
                  positivo e duradouro na sociedade.
                </SectionDescription>
                <Button to="/projetos">Conheça Nossos Projetos</Button>
              </SectionContent>
            </Section>

            {/* Donation Section */}
            <Section>
              <SectionImage
                style={{ backgroundImage: `url(${donateImage})` }}
              />
              <SectionContent>
                <SectionTitle>Doações</SectionTitle>
                <SectionDescription>
                  Faça a diferença! Sua doação nos ajuda a continuar nossos
                  projetos e alcançar cada vez mais pessoas. Apoie nossa causa
                  com qualquer valor.
                </SectionDescription>
                <Button to="/doacoes">Doe Agora</Button>
              </SectionContent>
            </Section>

            {/* Contact Section */}
            <Section>
              <SectionImage
                style={{ backgroundImage: `url(${contactImage})` }}
              />
              <SectionContent>
                <SectionTitle>Contato</SectionTitle>
                <SectionDescription>
                  Entre em contato para saber mais sobre o nosso trabalho, como
                  se voluntariar, ou para tirar dúvidas sobre doações e
                  parcerias.
                </SectionDescription>
                <Button to="/contato">Fale Conosco</Button>
              </SectionContent>
            </Section>
          </SectionsContainer>
        </HomeContainer>
      </BoxContainer>
    </Container>
  );
}

export default Home;

import React from "react";
import { Carousel } from "react-bootstrap";
import styled from "styled-components";

import { BoxContainer, Container } from "./styles";
import MiniMenu from "../components/mini-menu";
import {
  CI1,
  CI2,
  CI3,
  CI4,
  CII1,
  CII2,
  CII3,
  CII4,
  CII5,
  CIO1,
  CIO2,
  CIO3,
} from "../assets/images";

const projects = [
  {
    idDiv: "#projeto1",
    title: "CEI Crescendo com Atitude I e II",
    icon: 1,
    images: [CI1, CI2, CI3, CI4, CII1, CII2, CII3, CII4, CII5],
  },
  {
    idDiv: "#projeto2",
    title: "Cioeste - Cursos Profissionalizantes",
    icon: 2,
    images: [CIO1, CIO2, CIO3],
  },
];

const siteColors = {
  background: "#fff",
  grayBackground: "#f8f8f8",
};

const GallerySection = styled.section`
  padding: 50px 20px;
  background-color: ${(props) => props.bgColor || siteColors.background};
  position: relative;
  margin-bottom: 30px; /* Ajusta o espaço entre as seções */
`;
const CarouselImage = styled.img`
  width: 100%;
  border-radius: 10px;
  max-height: 500px; /* Aumenta a altura máxima das imagens */
  object-fit: cover;
`;

const ProjectTitle = styled.h2`
  font-size: 2rem;
  color: #220033;
  text-align: center;
  margin-bottom: 30px;
`;

const Galeria = () => {
  return (
    <Container>
      <BoxContainer>
        {projects.map((project, index) => (
          <GallerySection
            key={index}
            bgColor={
              index % 2 === 0
                ? siteColors.background
                : siteColors.grayBackground
            }
            id={project.idDiv}
          >
            <ProjectTitle>{project.title}</ProjectTitle>
            <Carousel controls={true} indicators interval={4000} pause={false}>
              {project.images.map((image, idx) => (
                <Carousel.Item key={idx}>
                  <CarouselImage
                    src={image}
                    alt={`Imagem do ${project.title}`}
                  />
                </Carousel.Item>
              ))}
            </Carousel>
          </GallerySection>
        ))}
        <MiniMenu projects={projects} />
      </BoxContainer>
    </Container>
  );
};

export default Galeria;

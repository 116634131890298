import React, { useEffect } from "react";
import styled, { keyframes } from "styled-components";

// Animação de fade-in e fade-out do texto
const fadeInOut = keyframes`
  0%, 100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
`;

// Animação de rotação do círculo
const rotate = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const PreLoaderContainer = styled.div`
  display: ${({ isVisible }) => (isVisible ? "flex" : "none")};
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(248, 248, 248, 0.9);
  z-index: 9999;
`;

const LoaderWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;
`;

const Spinner = styled.div`
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left: 4px solid #fd7e14;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  margin-bottom: 20px;
  animation: ${rotate} 1s linear infinite;
`;

const InstituteName = styled.div`
  font-size: 1.5rem;
  font-weight: bold;
  color: #220033;
  animation: ${fadeInOut} 2s ease-in-out infinite;
`;

const PreLoader = ({ setIsLoading }) => {
  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 2000);

    return () => clearTimeout(timer);
  }, [setIsLoading]);

  return (
    <PreLoaderContainer isVisible={true}>
      <LoaderWrapper>
        <Spinner />
        <InstituteName>Instituto Cecília Meireiles</InstituteName>
      </LoaderWrapper>
    </PreLoaderContainer>
  );
};

export default PreLoader;

import React, { useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import Home from "./pages/Home";
import QuemSomos from "./pages/QuemSomos";
import Projetos from "./pages/Projetos";
import Galeria from "./pages/Galeria";
import Contato from "./pages/Contato";
import Fundadores from "./pages/Fundadores";
import Navbar from "./components/navbar";
import Footer from "./components/footer";
import PreLoader from "./components/preloader";

function App() {
  const [isLoading, setIsLoading] = useState(true);
  return (
    <Router>
      {isLoading && <PreLoader setIsLoading={setIsLoading} />}
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/quem-somos" element={<QuemSomos />} />
        <Route path="/projetos" element={<Projetos />} />
        <Route path="/galeria" element={<Galeria />} />
        <Route path="/contato" element={<Contato />} />
        <Route path="/fundadores" element={<Fundadores />} />
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;
